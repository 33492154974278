<template>
  <div class="item w-100" style="justify-content: start;align-items: center;">
    <div
      class="item-img"
      :class="[
        index === 1
          ? 'green-pastel'
          : index === 2
          ? 'red-pastel'
          : index === 3
          ? 'yellow-pastel'
          : index === 4
          ? 'pink-pastel '
          : index === 5
          ? 'yellow-pastel'
          : 'blue-pastel',
      ]"
    >
      <img v-if="img" :src="require(`@/assets/${img}`)"  height="25px" width="33px"/>
      <i v-else class="fas fa-chart-pie" style="font-size: 24px"></i>
    </div>

    <div class="item-text">
      <div class="item-text__title mx-2">{{ data.label }}</div>
      <div class="item-text__subtitle ml-2">
        <span v-if="specialChar">$</span>
        {{ data.value }}
        <span v-if="specialChar">M</span>
        <span v-else-if="!specialChar">{{specialCharText}}</span>
        <!-- <span v-else>%</span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Statistics",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    img : {
       type: String,
      default: '',
    },
    specialChar: {
      type: Boolean,
      default: true
    },
    specialCharText:{
      type: String,
      default: "%"
    }
  },
};
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  position: relative;
}
.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.item-text {
  display: flex;
  justify-content: center;
  margin-left: 24px;
  padding: 2px;
  align-items: center;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #8394b4;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  color: #313846;
}
.blue-pastel {
  background: #67e8ff;
}
.green-pastel {
  background: #81f89e;
  
}

.pink-pastel {
  background: #faacfe;
}

.red-pastel {
  background: #ffa2a2;
}

.yellow-pastel {
  background: #ffdd7c;
}
</style>